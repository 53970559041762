import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  TextField,
} from "@mui/material";
import DownloadImage from "../assets/images/download.png";
import React, {
  ChangeEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactPlayer from "react-player";
import axios from "axios";
import { Root, SubmagicResponse, SubmagicFormat } from "../extras/types";
import { ColorContext } from "../extras/ColorContext";
import FeatureIntro from "../components/FeatureIntro";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://youtubeshortsdownload.com/api';
var static_video_url = "";

const sampleSubmagicResponse: SubmagicResponse = {
  url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
  formats: [
    {
      "formatId": 18,
      "label": "mp4 (360p)",
      "type": "video_with_audio",
      "ext": "mp4",
      "width": 360,
      "height": 640,
      "url": "https://rr4---sn-4g5lznek.googlevideo.com/videoplayback?expire=1725797952&ei=4EHdZve4Jqfl6dsPtoKtyQ0&ip=2a01%3A4f8%3A1c1c%3A15cc%3A4e08%3A6120%3Ab635%3A9942&id=o-AGeJI0bRJdkUeKNdlOrwUMrV01shzWT6Q9bPu4CjoKCG&itag=18&source=youtube&requiressl=yes&xpc=EgVo2aDSNQ%3D%3D&mh=2Y&mm=31%2C29&mn=sn-4g5lznek%2Csn-4g5edn6r&ms=au%2Crdu&mv=m&mvi=4&pl=51&initcwndbps=883750&bui=AQmm2exyDVBqUAFQrsgUBWwASXlEoS2UnplmC8HT4wjXwQwSSxyCMeMxl8n7iox0F8iGKxUxD1U9dzSc&spc=Mv1m9s3ToO5x_W2OfWMGkQCKv97R7SggZO7apNaRRmfBhgkl9dWm&vprv=1&svpuc=1&mime=video%2Fmp4&ns=Z0NGRO42pOBE6y7xfAiWsXAQ&rqh=1&cnr=14&ratebypass=yes&dur=227.044&lmt=1698429730293783&mt=1725775969&fvip=3&c=WEB&sefc=1&txp=5438434&n=oxVPyUusW8Q-mQ&sparams=expire%2Cei%2Cip%2Cid%2Citag%2Csource%2Crequiressl%2Cxpc%2Cbui%2Cspc%2Cvprv%2Csvpuc%2Cmime%2Cns%2Crqh%2Ccnr%2Cratebypass%2Cdur%2Clmt&sig=AJfQdSswRQIhAOiGMn1PNtivNkmqp9bgl0UH18XddbrpDhFTozJ8ZsTOAiBLRgoW-9DSiQnl8QP85iBZ29eRjPCDSZ12cgOwEUKCIw%3D%3D&lsparams=mh%2Cmm%2Cmn%2Cms%2Cmv%2Cmvi%2Cpl%2Cinitcwndbps&lsig=ABPmVW0wRQIgYzMKriTkCwsjKWqor1LGbbrFstkSyR77veExxDw3FBgCIQCA_QpOID-hozo9PFuH8DKIIeltH9hKiB1qx3Vg1SktJA%3D%3D",
      "bitrate": 702098,
      "fps": 30,
      "audioQuality": "AUDIO_QUALITY_LOW",
      "audioSampleRate": "44100",
      "mimeType": "video/mp4; codecs=\"avc1.42001E, mp4a.40.2\"",
      "duration": 3
  }
  ],
  thumbnailUrl: "https://i.ytimg.com/vi/dQw4w9WgXcQ/hqdefault.jpg",
  defaultFormatId: 18,
  duration: "216",
  title: "Rick Astley - Never Gonna Give You Up (Official Music Video)",
};


function HomePage(props: any) {
  const colorContex = useContext(ColorContext);
  const scrollRef = useRef<any>(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [audioResponse, setAudioResponse] = useState<SubmagicResponse>(sampleSubmagicResponse);
  const [playVideo, setPlayVideo] = useState(false);
  const [isTermsAggred, setIsTermsAggred] = useState(false);
  const [isDownloadSuccess, setIsDownloadSuccess] = useState(false);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    scrollToDiv();
    return () => {};
  }, [colorContex.point]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): any {
    setVideoUrl(event.target.value);
    console.log(event.target.value);
    console.info(videoUrl);
    if (videoUrl !== "" || videoUrl.includes("youtu")) {
      setPlayVideo(true);
    } else {
      setPlayVideo(false);
    }
  }

  function isValidYoutubeUrl(url: string): boolean {
    const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    return pattern.test(url);
  }

  function mimicDownload() {
    if (!isValidYoutubeUrl(videoUrl)) {
      alert("A Valid Youtube Video URL is Required!!");
      return;
    }
    // if (videoUrl === "" || !videoUrl.startsWith("https://youtu")) {
    //   alert("A Valid Youtube Video URL is Required!!");
    //   return;
    // }

    handleOpen();
    setAudioResponse(sampleSubmagicResponse);
    setIsDownloadSuccess(true);
    setPlayVideo(true);
    static_video_url = videoUrl;
    setTimeout(() => {
      handleClose();
      setVideoUrl("");
    }, 5000);
  }

  function handleCheckboxChange(checked: boolean) {
    setIsTermsAggred(checked);
    //setPlayVideo(checked);
  }

  function fetchDownloadableLink(): void {
    if (!isValidYoutubeUrl(videoUrl)) {
      alert("A Valid Youtube Video URL is Required!!");
      return;
    }
    handleOpen();
    axios.get(API_BASE_URL + videoUrl).then(
      (result) => {
        console.log("Hitting Youtube Dpwnload API is successful");
        setAudioResponse(result.data);
        setIsDownloadSuccess(true);
        setPlayVideo(true);
        static_video_url = result.data.url;
        setTimeout(() => {
          handleClose();
          setVideoUrl(static_video_url);
        }, 2000);
      },
      (error) => {
        console.log("Something went wrong while hitting data.." + error);
        handleClose();
        alert("Something went wrong while hitting data.." + error);
      }
    );
  }

  function handleVideoPlay(): any {
    if (!isDownloadSuccess) {
      alert('You need to download the video before playing..');
      return;
    }

    if (!isValidYoutubeUrl(videoUrl)) {
      alert("A Valid Youtube Video URL is Required!!");
      return;
    }
    static_video_url = videoUrl;
    setPlayVideo(true);
  }

  function openLink(audioUrl: string): any {
    if (audioUrl === "" || audioUrl.length < 20) {
      alert("Something went wrong while generating download link, try again..");
      return;
    }
    window.open(audioUrl, "_blank");
  }

  function scrollToDiv() {
    if (colorContex.point !== 0) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
      colorContex.setPoint(0);
    }
  }

  const backdrop = (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <div className="flex flex-col items-center">
          <CircularProgress color="inherit" />
          <h1 className="font-extrabold m-2 text-white text-xl">
            Communicating with server...
          </h1>
        </div>
      </Backdrop>
    </React.Fragment>
  );

  return (
    <div
      ref={scrollRef}
      className="md:m-10 sm:m-5 flex flex-col items-center justify-center"
    >
      {backdrop}
      <FeatureIntro
        heading="Free Youtube Shorts Downloader"
        desc="YouTubeShortsDownload.com brings your favorite online media right to your device, allowing you to download and keep YouTube Shorts, high-quality videos, audio, reels, and even thumbnails — all from a single link, and completely FREE! No more streaming limits or internet dependency — save your favorite content to your phone, PC, laptop, iPad, or any iOS device and enjoy offline access anytime."
        subheading="Using our downloader is easy: simply copy the link of the video you want, paste it into our tool, and it will automatically synchronize the content, offering you direct download links for videos, audio, and thumbnails. Discover a world where online media is yours to keep with YouTubeShortsDownload.com!➡️"
      />
      <div className="flex flex-col items-center border border-gray-500 shadow-lg p-4">
        <TextField
          fullWidth
          value={videoUrl}
          onChange={handleChange}
          id="url-input"
          label="Enter or Paste Youtube Link Here"
          variant="outlined"
        />
        <Button
          onClick={fetchDownloadableLink}
          sx={{ marginTop: "20px", marginBottom: "10px", width: "200px" }}
          variant="contained"
        >
          Download Shorts
        </Button>
        <Button
          onClick={handleVideoPlay}
          sx={{ width: "200px", marginTop: "10px", marginBottom: "15px" }}
          variant="outlined"
        >
          Play Shorts
        </Button>
        <h3 className="text-xs text-center w-80 m-2 p-2">
          A direct prompt to download shorts will get triggered if video has
          only one format else a list of downloadable video will get presented.
        </h3>
        <Divider color="black" />
      </div>

      <br />
      <br />

      {isDownloadSuccess && (
        <div className="w-full px-4 sm:px-6 md:px-8 flex justify-center mt-8 mb-8">
        <div className="w-full max-w-sm sm:max-w-md">
          <img
            src={audioResponse.thumbnailUrl}
            alt={audioResponse.title}
            className="w-full h-auto rounded-lg mx-auto"
          />
        </div>
      </div>
      )}

      {isDownloadSuccess && (
        <div className="border-2 text-center border-blue-500 shadow-sm p-4">
          <div className="flex flex-col items-center md:flex-row font-mono mb-5 justify-center">
            <h3 className="font-bold text-xl">Shorts Fetching Successful</h3>
            <img
              className="m-2"
              width="30px"
              height="30px"
              alt="download"
              src={DownloadImage}
            />
            <img
              className="animate-ping"
              width="30px"
              height="30px"
              alt="download"
              src={DownloadImage}
            />
          </div>

          {audioResponse.formats.map((format, index) => {
            let displayType = format.type;
            if (displayType === 'video_with_audio') {
              displayType = 'video + audio';
            } else if (displayType === 'video_only') {
              displayType = 'video';
            }

            return (
              <Button
                sx={{ margin: "10px", color: "blue", fontWeight: "bold" }}
                key={index}
                variant="outlined"
                onClick={() => openLink(format.url)}
              >
                {displayType}
                {' ['}
                {format.label}
                {'] '}
                Download 
              </Button>
            );
          })}
        </div>
      )}


    </div>
  );
}

export default HomePage;
