import FaqComponent from "./FaqComponent";

function FaqList(props: any) {
  return (
    <div className="p-4 mt-20 lg:mx-20">
      <h1>FAQ (How it works)</h1>
      <div
        className="mt-3 mb-3"
        style={{ borderBottomWidth: "1px", borderBottomColor: "lightgray" }}
      />
      <FaqComponent
        faq="How can I download youtube shorts videos?"
        answer="To download YouTube Shorts directly to your mobile, iOS, or PC, simply visit **YouTubeShortsDownload.com**. Copy the link of the YouTube Shorts video you want, paste it into the input box on our site, and hit the download button to start the process. It's that simple! This is a fast, free, and easy way to download short videos online without any hassle."
      />
      <FaqComponent
        faq="What formats can I download in?"
        answer="You can choose from various formats like MP4, MP3, M4A, WebM, and more, depending on the original content and platform. We usually offer the highest available quality."
      />
      <FaqComponent
        faq="Is downloading copyrighted content legal?"
        answer="Downloading YouTube videos may violate YouTube's guidelines, so it's important to carefully review their policies before proceeding. We do not claim any rights to the videos or images— all credit and ownership belong to YouTube and its respective creators.For more information, please review YouTube’s Terms of Service."
      />
      <FaqComponent
        faq="Is there a limit on how much I can download?"
        answer="Feel free to use the YouTube Shorts Downloader online with no limitations on downloading YouTube Shorts videos. Our tool is dedicated to providing seamless service to our valued users, ensuring a smooth and easy experience when downloading any type of YouTube Shorts video. We are committed to offering continuous, hassle-free access to your favorite content."
      />
      <FaqComponent
        faq="Is my data safe when using your service?"
        answer="We prioritize user privacy and security. We encrypt connections and do not store any personal information like download history."
      />
      <FaqComponent
        faq="What happens if my download fails?"
        answer="Some platform restrictions or internet issues might cause occasional download failures. We recommend trying again later or contacting us for support."
      />
      <FaqComponent
        faq="Term and Condition"
        answer="This site is for educational purposes only. YouTubeShortsDownload.com does not claim any rights to the videos, photos, or images downloaded through our tool. All rights belong to the original creators or YouTube. We only use publicly available information. YouTube and its logos are trademarks and copyrights of Google."
      />
    </div>
  );
}

export default FaqList;
